
import React, { useState } from 'react';
import '../FirstPage.css'
import searchinterfacesymbol from '../../../Assets/search-interface-symbol.png'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { FaClipboardCheck, FaTruck, FaCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

import casefiled from '../../../Assets/casefiled.png'
import telephonecall from '../../../Assets/telephonecall.png'
import checksuccess from '../../../Assets/checksuccess.png'
import crossUser from '../../../Assets/crossUser.png'


import Registration from '../../Registration/Registration';

const QuotationUpdate = () => {
    const [currentStage, setCurrentStage] = useState(1); // Example stage
    const [currentStage1, setCurrentStage1] = useState(2); // Example stage
    const [isImageContainerVisible, setIsImageContainerVisible] = useState(true);
    const [caseDetails, setCaseDetails] = useState(false);

    const navigate = useNavigate()


    // Array of stages
    // const stages = ["assigned", "accepted & moved","reached"];
    const stages = [
        { label: "Filed", img: casefiled },
        { label: "Connecting Vendor", img: telephonecall },
        { label: "Assigned", img: checksuccess },
    ];


    const goToMap = () => {
        navigate('/SelectLocationOnMap', { state: { center: [28.7041, 77.1025] } })
    }

    return (
        <div>
            <div className="container h-100" style={{
                filter: isImageContainerVisible ? "blur(3px)" : "none", // Apply blur effect
                opacity: isImageContainerVisible ? 0.9 : 1, // Reduce opacity if blurred
                pointerEvents: isImageContainerVisible ? "none" : "auto" // Disable clicking
            }}>
                <div className="d-flex justify-content-center h-100">
                    <div className="searchbar" style={{ border: '1px solid', minWidth: "300px" }}>
                        <input className="search_input" type="text" placeholder="Search..." />
                        {/* <a href="#" className="search_icon">
                            <i className="fas fa-search"></i>
                        </a> */}
                        <img src={searchinterfacesymbol} className="search_icon" style={{ height: '15px', width: '15px' }} alt='search' />

                    </div>
                </div>
            </div>

            <div style={{
                filter: isImageContainerVisible ? "blur(3px)" : "none", // Apply blur effect
                opacity: isImageContainerVisible ? 0.9 : 1, // Reduce opacity if blurred
                pointerEvents: isImageContainerVisible ? "none" : "auto",
                border: "1px solid teal", minWidth: "280px", margin: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 3px 4px 12px 8px', borderRadius: "5px", padding: "10px"
            }}>
                <div style={{ display: "flex", alignItems: "center", margin: "20px 0px 0px 0px" }}>
                    {stages.map((stage, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                                position: "relative",
                                flex: 1,
                            }}
                        >
                            {/* Icon/Image for each stage */}
                            <div
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    backgroundColor: index === currentStage1 ? "#4CAF50" : "#ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: index === currentStage1 ? "2px solid #4CAF50" : "none",
                                    transition: "background-color 0.3s ease",
                                    zIndex: 1,
                                }}
                            >
                                <img
                                    src={stage.img}
                                    alt={stage.label}
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        opacity: index <= currentStage1 ? 1 : 0.5,
                                    }}
                                />
                            </div>

                            {/* Stage Label */}
                            <p
                                style={{
                                    marginTop: "5px",
                                    color: index <= currentStage1 ? "black" : "#aaa",
                                    fontWeight: index === currentStage1 ? "bold" : "normal",
                                    fontSize: "12px",
                                }}
                            >
                                {stage.label}
                            </p>

                            {/* Connecting Line */}
                            {index < stages.length - 1 && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "15px", // Aligns with the center of the icon
                                        left: "50%",
                                        right: "-50%",
                                        width: "100%",
                                        height: "2px",
                                        backgroundColor: index < currentStage1 ? "#4CAF50" : "#ccc",
                                        zIndex: 0,
                                    }}
                                ></div>
                            )}
                        </div>
                    ))}
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <div style={{ display: "flex", alignItems: "center", margin: '20px 5px 0px 10px' }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Vehicle No:</p>
                        <span style={{ color: "blue", marginLeft: "5px", fontSize: "12px" }}>MH 14 FE 6020</span>
                    </div>


                    <div style={{ marginTop: "5px", marginRight: "10px", width: "35px", background: '#ccb300', border: "1px solid red", borderRadius: "5px", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: 'center', color: 'black' }}>4.5</div>
                </div>

                <div style={{ display: "flex", alignItems: "center", margin: '5px 5px 0px 10px' }}>
                    <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}> Vendor Fare: </p>
                    <span style={{ marginLeft: "5px", fontSize: "12px", color: 'darkblue', fontWeight: "bold" }}>₹ 10,000</span>
                </div>
                <div style={{ display: "flex", alignItems: "center", margin: '7px 5px 0px 10px' }}>
                    <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}> Vendor Distance: </p>
                    <span style={{ marginLeft: "5px", fontSize: "12px", color: 'darkblue', fontWeight: "bold" }}>5 KM</span>
                </div>
                <div style={{ display: "flex", alignItems: "center", margin: '5px 5px 0px 10px' }}>
                    <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0, fontWeight: "bold" }}>Current Status:</p>
                    <span style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "5px", padding: "3px 10px 5px 10px", fontSize: "12px", borderRadius: "10px", color: 'green', border: "1px solid green", background: 'white' }}>Assigned</span>
                </div>

                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                    <p style={{
                        fontSize: '11px',
                        marginTop: "5px",
                        background: "green",
                        padding: "10px",
                        border: '1px solid blue',
                        textAlign: 'center',
                        borderRadius: '30px',
                        fontWeight: "bold",
                        color: "white",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "center",
                        position: "relative",
                        cursor: "pointer",
                        margin: '5px 5px 5px 5px',
                        maxWidth: "400px",
                        minWidth: "150px",
                    }} onClick={(e) => setIsImageContainerVisible(true)} >
                        <KeyboardDoubleArrowRightIcon style={{
                            position: "absolute",
                            left: '10px'
                        }} />
                        View More
                    </p>
                    <p style={{
                        fontSize: '11px',
                        marginTop: "5px",
                        background: "white",
                        padding: "10px",
                        border: '2px solid #000000',
                        textAlign: 'center',
                        borderRadius: '30px',
                        fontWeight: "bold",
                        color: "blue",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "center",
                        position: "relative",
                        cursor: "pointer",
                        maxWidth: "400px",
                        minWidth: "150px",
                        margin: '5px 5px 5px 5px',
                        height: "30px"
                    }}>
                        Cancel Process
                        <img src={crossUser} style={{
                            position: "absolute",
                            right: '10px', width: '20px', height: '20px'
                        }} />
                    </p>

                </div>


            </div>



            <div style={{
                filter: isImageContainerVisible ? "blur(3px)" : "none", // Apply blur effect
                opacity: isImageContainerVisible ? 0.9 : 1, // Reduce opacity if blurred
                pointerEvents: isImageContainerVisible ? "none" : "auto",
                border: "1px solid teal", minWidth: "280px", margin: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 3px 4px 12px 8px', borderRadius: "5px", padding: "10px"
            }}>
                <div style={{ display: "flex", alignItems: "center", margin: "20px 0px 0px 0px" }}>
                    {stages.map((stage, index) => (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center",
                                position: "relative",
                                flex: 1,
                            }}
                        >
                            {/* Icon/Image for each stage */}
                            <div
                                style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    backgroundColor: index === currentStage ? "#4CAF50" : "#ccc",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    border: index === currentStage ? "2px solid #4CAF50" : "none",
                                    transition: "background-color 0.3s ease",
                                    zIndex: 1,
                                }}
                            >
                                <img
                                    src={stage.img}
                                    alt={stage.label}
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        opacity: index <= currentStage ? 1 : 0.5,
                                    }}
                                />
                            </div>

                            {/* Stage Label */}
                            <p
                                style={{
                                    marginTop: "5px",
                                    color: index <= currentStage ? "black" : "#aaa",
                                    fontWeight: index === currentStage ? "bold" : "normal",
                                    fontSize: "12px",
                                }}
                            >
                                {stage.label}
                            </p>

                            {/* Connecting Line */}
                            {index < stages.length - 1 && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "15px", // Aligns with the center of the icon
                                        left: "50%",
                                        right: "-50%",
                                        width: "100%",
                                        height: "2px",
                                        backgroundColor: index < currentStage ? "#4CAF50" : "#ccc",
                                        zIndex: 0,
                                    }}
                                ></div>
                            )}
                        </div>
                    ))}
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                    <div style={{ display: "flex", alignItems: "center", margin: '20px 5px 0px 10px' }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Vehicle No:</p>
                        <span style={{ color: "blue", marginLeft: "5px", fontSize: "12px" }}>MH 14 FE 6020</span>
                    </div>


                    {/* <div style={{ marginTop: "5px", marginRight: "10px", width: "35px", background: '#ccb300', border: "1px solid red", borderRadius: "5px", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: 'center', color: 'black' }}>4.5</div> */}
                </div>

                <div style={{ display: "flex", alignItems: "center", margin: '5px 5px 0px 10px' }}>
                    <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Approx time for process : </p>
                    <span style={{ marginLeft: "5px", fontSize: "12px", color: 'darkblue', fontWeight: "bold" }}>30 minutes</span>
                </div>
                <div style={{ display: "flex", alignItems: "center", margin: '3px 5px 0px 10px' }}>
                    <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0, fontWeight: "bold" }}>Current Status:</p>
                    <span style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "5px", padding: "3px 10px 5px 10px", fontSize: "12px", borderRadius: "10px", color: 'blue', border: "1px solid green", background: 'white' }}>Processing...</span>
                </div>

                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                    <p style={{
                        fontSize: '11px',
                        marginTop: "5px",
                        background: "green",
                        padding: "10px",
                        border: '1px solid blue',
                        textAlign: 'center',
                        borderRadius: '30px',
                        fontWeight: "bold",
                        color: "white",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "center",
                        position: "relative",
                        cursor: "pointer",
                        margin: '5px 5px 5px 5px',
                        maxWidth: "400px",
                        minWidth: "150px",
                    }} onClick={(e) => setCaseDetails(true)}>
                        <KeyboardDoubleArrowRightIcon style={{
                            position: "absolute",
                            left: '10px'
                        }} />
                        View More
                    </p>
                    <p style={{
                        fontSize: '11px',
                        marginTop: "5px",
                        background: "white",
                        padding: "10px",
                        border: '2px solid #000000',
                        textAlign: 'center',
                        borderRadius: '30px',
                        fontWeight: "bold",
                        color: "blue",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: "center",
                        position: "relative",
                        cursor: "pointer",
                        maxWidth: "400px",
                        minWidth: "150px",
                        margin: '5px 5px 5px 5px',
                        height: "30px"
                    }}>
                        Cancel Process
                        <img src={crossUser} style={{
                            position: "absolute",
                            right: '10px', width: '20px', height: '20px'
                        }} />
                    </p>

                </div>


            </div>



            {isImageContainerVisible && (

                <div style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
                    zIndex: 1000, // ensure it appears above other content
                    display: "flex",
                    alignItems: "flex-end", // positions the container at the bottom
                    justifyContent: "center",
                    animation: "slideUp 0.5s ease-out" // apply the animation
                }}>

                    <div style={{ position: "absolute", width: "90%", maxWidth: "600px", marginBottom: "430px" }}>
                        {/* Cross Icon */}
                        <img src={crossUser} onClick={(e) => setIsImageContainerVisible(false)}
                            style={{
                                position: "absolute",
                                top: "-10px", // Position slightly above the container
                                left: "50%",
                                width: '25px',
                                height: '25px',
                                cursor: "pointer",
                                zIndex: 1001, // Ensure it’s above other elements
                                filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.5))"
                            }}
                        // Add a close function if needed
                        />
                    </div>


                    <div className="image-container" style={{
                        backgroundColor: "#ffffff",
                        padding: "20px",
                        borderRadius: "15px 15px 0px 0px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                        maxWidth: "600px",
                        width: "90%"
                    }}>

                        <div className="background-image"></div>

                        <div className="text-overlay">
                            <p style={{
                                fontSize: '14px',
                                padding: "5px",
                                border: '3px solid blue',
                                borderImage: 'linear-gradient(to top, white 10% , black 90%) 1',
                                textAlign: 'center',
                                borderRadius: '30px',
                                fontWeight: "bold"
                            }}>
                                Case Assigned!
                            </p>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p style={{ textAlign: "center", marginLeft: "120px", marginTop: "10px", fontSize: "14px" }}>Vendor Fare</p>
                                <div style={{
                                    marginTop: "5px",
                                    width: "30px",
                                    background: '#ccb300',
                                    border: "1px solid red",
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: 'center',
                                    color: 'black'
                                }}>4.5</div>
                            </div>
                            <h1 style={{ textAlign: "center", fontSize: "23px", fontWeight: "bold" }}>₹ 10,000</h1>

                            <div style={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                <p style={{
                                    textAlign: "center",
                                    marginTop: "7px",
                                    fontSize: '14px',
                                    paddingRight: '10px',
                                    fontWeight: 'bold'
                                }}>Vendor Distance :</p>
                                <p style={{ color: 'Green', marginTop: "5px", fontSize: "19px" }}>5 km</p>
                            </div>

                            <div className="text-overlay text-overlay2">
                                <h4 style={{ marginBottom: '5px', fontSize: "11px", marginTop: "10px" }}>Location:</h4>
                                <p style={{ fontSize: '11px', gap: "10px" }}>205 D/15, Indl Estate, L B S Marg, Opp I O L, Near Amrutnagar, Near Ayodhya Chowk, Rohini, K Marg, Lower Parel Mumbai Maharashtra 4000067</p>

                                <p style={{
                                    fontSize: '12px',
                                    marginTop: "5px",
                                    background: "white",
                                    padding: "10px",
                                    border: '2px solid #000000',
                                    textAlign: 'center',
                                    borderRadius: '30px',
                                    fontWeight: "bold",
                                    color: "blue",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "center",
                                    position: "relative",
                                    cursor: "pointer",
                                    maxWidth: "400px",
                                    minWidth: "150px"
                                }} onClick={goToMap}>
                                    Vendor Current Location
                                    <KeyboardDoubleArrowRightIcon style={{
                                        position: "absolute",
                                        left: '10px'
                                    }} />
                                </p>

                                <p style={{
                                    fontSize: '11px',
                                    marginTop: "5px",
                                    background: "green",
                                    padding: "10px",
                                    border: '1px solid blue',
                                    textAlign: 'center',
                                    borderRadius: '30px',
                                    fontWeight: "bold",
                                    color: "white",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "center",
                                    position: "relative",
                                    cursor: "pointer"
                                }}>
                                    <KeyboardDoubleArrowRightIcon style={{
                                        position: "absolute",
                                        left: '10px'
                                    }} />
                                    Accept Vendor's Deal
                                </p>

                                <p style={{
                                    fontSize: '11px',
                                    marginTop: "5px",
                                    background: "#8f4325",
                                    padding: "10px",
                                    border: '1px solid blue',
                                    textAlign: 'center',
                                    borderRadius: '30px',
                                    fontWeight: "bold",
                                    color: "white",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: "center",
                                    position: "relative",
                                    cursor: "pointer"
                                }}>
                                    Reject Deal
                                    <KeyboardDoubleArrowLeftIcon style={{
                                        position: 'absolute',
                                        right: "10px"
                                    }} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            {caseDetails && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent background
                        zIndex: 1000,
                        display: "flex",
                        alignItems: "flex-end", // positions the container at the bottom
                        justifyContent: "center",
                        animation: "slideUp 0.5s ease-out",
                    }}
                >
                    {/* Modal Container */}
                    <div
                        style={{
                            position: "relative",
                            width: "90%",
                            maxWidth: "600px",
                            backgroundColor: "#fff", // white background for the content
                            borderRadius: "15px 15px 0px 0px",
                            // marginBottom: "30px",
                            maxHeight: "80%", // limit the height for scrollability
                            overflowY: "auto", // enables vertical scrolling
                            padding: "20px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                        }}
                    >
                        {/* Cross Icon */}
                        <img
                            src={crossUser}
                            onClick={() => setCaseDetails(false)}
                            style={{
                                position: "absolute",
                                // top: "-10px",
                                left: "calc(100% - 35px)",
                                width: "25px",
                                height: "25px",
                                cursor: "pointer",
                                zIndex: 1001,
                                filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.5))",
                            }}
                        />

                        {/* Scrollable Registration Content */}
                        <Registration />
                    </div>
                </div>
            )}



        </div>
    )
}




export default QuotationUpdate;