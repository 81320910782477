export const targetVsReality = {
    reality: [78, 68, 58, 78, 95, 95, 95],
    target: [102, 92, 116, 92, 133, 133, 133],
  };
  
  export const salesData = [
    {
      label: 'Reality Sales',
      type: 'Global',
      value: 8.823,
      color: 'green.main',
      iconBgColor: 'green.light',
      icon: 'solar:bag-5-outline',
      iconColor: 'success.light',
    },
    {
      label: 'Target Sales',
      type: 'Commercial',
      value: 12.122,
      color: 'warning.main',
      iconBgColor: 'warning.lighter',
      icon: 'solar:ticker-star-outline',
      iconColor: 'warning.darker',
    },
  ];
  