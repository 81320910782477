

import React, { useState } from 'react';
import '../FirstPage.css'
import searchinterfacesymbol from '../../../Assets/search-interface-symbol.png'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { FaClipboardCheck, FaTruck, FaCheckCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

import assignedTask from '../../../Assets/assignedTask.png'
import comingCrane from '../../../Assets/comingCrane.png'
import checksuccess from '../../../Assets/checksuccess.png'
import Registration from '../../Registration/Registration';
import QuotationUpdate from './QuotationUpdate';




const CraneFirstPage = () => {
    const navigate = useNavigate()
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleSelect = (index) => {
      setSelectedIndex(index);
    };



// status tracking

    const [currentStage, setCurrentStage] = useState(1); // Example stage

    // Array of stages
    // const stages = ["assigned", "accepted & moved","reached"];
    const stages = [
        { label: "assigned", img: assignedTask },
        { label: "accepted & moved", img: comingCrane },
        { label: "reached", img: checksuccess },
    ];

    const goToMap = () => {
        navigate('/SelectLocationOnMap', { state: { center: [28.7041, 77.1025] } })
    }

    return (
        <div>
            <div className="start-container" style={{ height: "30px", margin: "20px 10px 0px 20px" }}>
                <div className="imageContainer">
                    {["status tracking", "new case", "quotes & updates", "summary & reviews", 'history & receipts'].map((text, index) => (
                        <div
                            key={index}
                            style={{cursor:'pointer'}}
                            className={`imageWrapper ${selectedIndex === index ? "selected" : ""}`}
                            onClick={() => handleSelect(index)}
                        >
                            {/* Add image element here if needed */}
                            <div className="top-scrolling">
                                <p>{text}</p>
                            </div>
                        </div>
                    ))}


                </div>
            </div>




          {selectedIndex == 0 && (  <div>

                <div className="container h-100">
                    <div className="d-flex justify-content-center h-100">
                        <div className="searchbar" style={{ border: '1px solid', minWidth: "300px" }}>
                            <input className="search_input" type="text" placeholder="Search..." />
                            {/* <a href="#" className="search_icon">
                            <i className="fas fa-search"></i>
                        </a> */}
                            <img src={searchinterfacesymbol} className="search_icon" style={{ height: '15px', width: '15px' }} alt='search' />

                        </div>
                    </div>
                </div>
                <div style={{ border: "1px solid teal", minWidth: "280px", margin: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 3px 4px 12px 8px', borderRadius: "5px", padding: "10px" }}>
                    <div style={{ display: "flex", alignItems: "center", margin: "20px 0px 0px 0px" }}>
                        {stages.map((stage, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    flex: 1,
                                }}
                            >
                                {/* Icon/Image for each stage */}
                                <div
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        backgroundColor: index === currentStage ? "#4CAF50" : "#ccc",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: index === currentStage ? "2px solid #4CAF50" : "none",
                                        transition: "background-color 0.3s ease",
                                        zIndex: 1,
                                    }}
                                >
                                    <img
                                        src={stage.img}
                                        alt={stage.label}
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            opacity: index <= currentStage ? 1 : 0.5,
                                        }}
                                    />
                                </div>

                                {/* Stage Label */}
                                <p
                                    style={{
                                        marginTop: "5px",
                                        color: index <= currentStage ? "black" : "#aaa",
                                        fontWeight: index === currentStage ? "bold" : "normal",
                                        fontSize: "12px",
                                    }}
                                >
                                    {stage.label}
                                </p>

                                {/* Connecting Line */}
                                {index < stages.length - 1 && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "15px", // Aligns with the center of the icon
                                            left: "50%",
                                            right: "-50%",
                                            width: "100%",
                                            height: "2px",
                                            backgroundColor: index < currentStage ? "#4CAF50" : "#ccc",
                                            zIndex: 0,
                                        }}
                                    ></div>
                                )}
                            </div>
                        ))}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <div style={{ display: "flex", alignItems: "center", margin: '20px 5px 0px 10px' }}>
                            <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Vehicle No:</p>
                            <span style={{ color: "blue", marginLeft: "5px", fontSize: "12px" }}>MH 14 FE 6020</span>
                        </div>


                        <div style={{ marginTop: "5px", marginRight: "10px", width: "35px", background: '#ccb300', border: "1px solid red", borderRadius: "5px", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: 'center', color: 'black' }}>4.5</div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", margin: '5px 5px 0px 10px' }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Approx time to reach : </p>
                        <span style={{ marginLeft: "5px", fontSize: "12px", color: 'darkblue', fontWeight: "bold" }}>10 minutes</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: '3px 5px 0px 10px' }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0, fontWeight: "bold" }}>Current Status:</p>
                        <span style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "5px", padding: "5px", fontSize: "12px", borderRadius: "10px", color: 'green', border: "1px solid green", background: 'white' }}>on the way</span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                        <p style={{
                            fontSize: '11px',
                            marginTop: "5px",
                            background: "green",
                            padding: "10px",
                            border: '1px solid blue',
                            textAlign: 'center',
                            borderRadius: '30px',
                            fontWeight: "bold",
                            color: "white",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            position: "relative",
                            cursor: "pointer",
                            margin: '5px 5px 5px 5px',
                            maxWidth: "400px",
                            minWidth: "150px",
                        }} >
                            <KeyboardDoubleArrowRightIcon style={{
                                position: "absolute",
                                left: '10px'
                            }} />
                            View More
                        </p>
                        <p style={{
                            fontSize: '11px',
                            marginTop: "5px",
                            background: "white",
                            padding: "10px",
                            border: '2px solid #000000',
                            textAlign: 'center',
                            borderRadius: '30px',
                            fontWeight: "bold",
                            color: "blue",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            position: "relative",
                            cursor: "pointer",
                            maxWidth: "400px",
                            minWidth: "150px",
                            margin: '5px 5px 5px 5px',
                            height: "30px"
                        }} onClick={goToMap}>
                            Track Location
                            <KeyboardDoubleArrowRightIcon style={{
                                position: "absolute",
                                left: '10px'
                            }} />
                        </p>

                    </div>


                </div>

                <div style={{ border: "1px solid teal", minWidth: "280px", margin: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 3px 4px 12px 8px', borderRadius: "5px", padding: "10px" }}>
                    <div style={{ display: "flex", alignItems: "center", margin: "20px 0px 0px 0px" }}>
                        {stages.map((stage, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    flex: 1,
                                }}
                            >
                                {/* Icon/Image for each stage */}
                                <div
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        backgroundColor: index === currentStage ? "#4CAF50" : "#ccc",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: index === currentStage ? "2px solid #4CAF50" : "none",
                                        transition: "background-color 0.3s ease",
                                        zIndex: 1,
                                    }}
                                >
                                    <img
                                        src={stage.img}
                                        alt={stage.label}
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            opacity: index <= currentStage ? 1 : 0.5,
                                        }}
                                    />
                                </div>

                                {/* Stage Label */}
                                <p
                                    style={{
                                        marginTop: "5px",
                                        color: index <= currentStage ? "black" : "#aaa",
                                        fontWeight: index === currentStage ? "bold" : "normal",
                                        fontSize: "12px",
                                    }}
                                >
                                    {stage.label}
                                </p>

                                {/* Connecting Line */}
                                {index < stages.length - 1 && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "15px", // Aligns with the center of the icon
                                            left: "50%",
                                            right: "-50%",
                                            width: "100%",
                                            height: "2px",
                                            backgroundColor: index < currentStage ? "#4CAF50" : "#ccc",
                                            zIndex: 0,
                                        }}
                                    ></div>
                                )}
                            </div>
                        ))}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <div style={{ display: "flex", alignItems: "center", margin: '20px 5px 0px 10px' }}>
                            <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Vehicle No:</p>
                            <span style={{ color: "blue", marginLeft: "5px", fontSize: "12px" }}>MH 14 SE 2313</span>
                        </div>


                        <div style={{ marginTop: "5px", marginRight: "10px", width: "35px", background: '#ccb300', border: "1px solid red", borderRadius: "5px", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: 'center', color: 'black' }}>4.5</div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", margin: '5px 5px 0px 10px' }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Approx time to reach : </p>
                        <span style={{ marginLeft: "5px", fontSize: "12px", color: 'darkblue', fontWeight: "bold" }}>10 minutes</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: '3px 5px 0px 10px' }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0, fontWeight: "bold" }}>Current Status:</p>
                        <span style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "5px", padding: "5px", fontSize: "12px", borderRadius: "10px", color: 'green', border: "1px solid green", background: 'white' }}>on the way</span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                        <p style={{
                            fontSize: '11px',
                            marginTop: "5px",
                            background: "green",
                            padding: "10px",
                            border: '1px solid blue',
                            textAlign: 'center',
                            borderRadius: '30px',
                            fontWeight: "bold",
                            color: "white",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            position: "relative",
                            cursor: "pointer",
                            margin: '5px 5px 5px 5px',
                            maxWidth: "400px",
                            minWidth: "150px",
                        }} >
                            <KeyboardDoubleArrowRightIcon style={{
                                position: "absolute",
                                left: '10px'
                            }} />
                            View More
                        </p>
                        <p style={{
                            fontSize: '11px',
                            marginTop: "5px",
                            background: "white",
                            padding: "10px",
                            border: '2px solid #000000',
                            textAlign: 'center',
                            borderRadius: '30px',
                            fontWeight: "bold",
                            color: "blue",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            position: "relative",
                            cursor: "pointer",
                            maxWidth: "400px",
                            minWidth: "150px",
                            margin: '5px 5px 5px 5px',
                            height: "30px"
                        }} >
                            Track Location
                            <KeyboardDoubleArrowRightIcon style={{
                                position: "absolute",
                                left: '10px'
                            }} />
                        </p>

                    </div>


                </div>

                <div style={{ border: "1px solid teal", minWidth: "280px", margin: '10px', boxShadow: 'rgba(0, 0, 0, 0.2) 3px 4px 12px 8px', borderRadius: "5px", padding: "10px" }}>
                    <div style={{ display: "flex", alignItems: "center", margin: "20px 0px 0px 0px" }}>
                        {stages.map((stage, index) => (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    textAlign: "center",
                                    position: "relative",
                                    flex: 1,
                                }}
                            >
                                {/* Icon/Image for each stage */}
                                <div
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        borderRadius: "50%",
                                        backgroundColor: index === currentStage ? "#4CAF50" : "#ccc",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        border: index === currentStage ? "2px solid #4CAF50" : "none",
                                        transition: "background-color 0.3s ease",
                                        zIndex: 1,
                                    }}
                                >
                                    <img
                                        src={stage.img}
                                        alt={stage.label}
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            opacity: index <= currentStage ? 1 : 0.5,
                                        }}
                                    />
                                </div>

                                {/* Stage Label */}
                                <p
                                    style={{
                                        marginTop: "5px",
                                        color: index <= currentStage ? "black" : "#aaa",
                                        fontWeight: index === currentStage ? "bold" : "normal",
                                        fontSize: "12px",
                                    }}
                                >
                                    {stage.label}
                                </p>

                                {/* Connecting Line */}
                                {index < stages.length - 1 && (
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "15px", // Aligns with the center of the icon
                                            left: "50%",
                                            right: "-50%",
                                            width: "100%",
                                            height: "2px",
                                            backgroundColor: index < currentStage ? "#4CAF50" : "#ccc",
                                            zIndex: 0,
                                        }}
                                    ></div>
                                )}
                            </div>
                        ))}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <div style={{ display: "flex", alignItems: "center", margin: '20px 5px 0px 10px' }}>
                            <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Vehicle No:</p>
                            <span style={{ color: "blue", marginLeft: "5px", fontSize: "12px" }}>MH 14 SE 2313</span>
                        </div>


                        <div style={{ marginTop: "5px", marginRight: "10px", width: "35px", background: '#ccb300', border: "1px solid red", borderRadius: "5px", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: 'center', color: 'black' }}>4.5</div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", margin: '5px 5px 0px 10px' }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0 }}>Approx time to reach : </p>
                        <span style={{ marginLeft: "5px", fontSize: "12px", color: 'darkblue', fontWeight: "bold" }}>10 minutes</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", margin: '3px 5px 0px 10px' }}>
                        <p style={{ fontSize: "13px", fontWeight: "bold", margin: 0, fontWeight: "bold" }}>Current Status:</p>
                        <span style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "5px", padding: "5px", fontSize: "12px", borderRadius: "10px", color: 'green', border: "1px solid green", background: 'white' }}>on the way</span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
                        <p style={{
                            fontSize: '11px',
                            marginTop: "5px",
                            background: "green",
                            padding: "10px",
                            border: '1px solid blue',
                            textAlign: 'center',
                            borderRadius: '30px',
                            fontWeight: "bold",
                            color: "white",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            position: "relative",
                            cursor: "pointer",
                            margin: '5px 5px 5px 5px',
                            maxWidth: "400px",
                            minWidth: "150px",
                        }} >
                            <KeyboardDoubleArrowRightIcon style={{
                                position: "absolute",
                                left: '10px'
                            }} />
                            View More
                        </p>
                        <p style={{
                            fontSize: '11px',
                            marginTop: "5px",
                            background: "white",
                            padding: "10px",
                            border: '2px solid #000000',
                            textAlign: 'center',
                            borderRadius: '30px',
                            fontWeight: "bold",
                            color: "blue",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center",
                            position: "relative",
                            cursor: "pointer",
                            maxWidth: "400px",
                            minWidth: "150px",
                            margin: '5px 5px 5px 5px',
                            height: "30px"
                        }} >
                            Track Location
                            <KeyboardDoubleArrowRightIcon style={{
                                position: "absolute",
                                left: '10px'
                            }} />
                        </p>

                    </div>


                </div>
            </div>)}

            {selectedIndex == 1 && (
                <Registration/>
            )}

            {selectedIndex == 2 && (
                <QuotationUpdate/>
            )}

        </div>
    )
}

export default CraneFirstPage;